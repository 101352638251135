import $http from './client';

const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CLIST}`;

// constante para definir o tamanho máximo de cada requisição
const CHUNK_SIZE = 400;

async function bulkCreateContacts(payload) {
  if (payload.length <= CHUNK_SIZE) {
    await $http.post(`${service}/contact/bulk`, payload);
    return;
  }

  const chunks = Math.ceil(payload.length / CHUNK_SIZE);
  const chunkedArray = [];

  for (let i = 0; i < chunks; i += 1) {
    const start = i * CHUNK_SIZE;
    const end = start + CHUNK_SIZE;
    chunkedArray.push(payload.slice(start, end));
  }

  const requests = chunkedArray.map((chunk) => $http.post(`${service}/contact/bulk`, chunk));

  await Promise.all(requests);
}

async function createContact(contactData) {
  const { data } = await $http.post(`${service}/contact`, contactData);
  return data;
}

async function editContact(contactId, contactData) {
  const { data } = await $http.put(`${service}/contact/${contactId}`, contactData);
  return data;
}

async function destroyContact(contactId) {
  const { data } = await $http.delete(`${service}/contact/${contactId}`);
  return data;
}

export const getContactById = async (contactId, config = { params: {}, signal: null }) => {
  const { data } = await $http.get(`${service}/contact/${contactId}`, config);
  return data;
};

async function getContactsByNameOrEmail(payload) {
  const { data } = await $http.get(`${service}/email/search`, { params: payload });
  return data;
}

async function getContactsPhones(payload) {
  const { data } = await $http.get(`${service}/contact/phone/search`, { params: payload });
  return data;
}

async function getOrganizationList(params) {
  try {
    const { data } = await $http.get(`${service}/organization`, { params });
    return data;
  } catch (error) {
    return [];
  }
}

async function getOrganizationsCount(params) {
  const { data } = await $http.get(`${service}/organization/count`, { params });
  return data;
}

async function getOrganizationById(organizationId) {
  try {
    const { data } = await $http.get(`${service}/organization/${organizationId}`);
    return data;
  } catch (error) {
    return [];
  }
}

async function createOrganization(payload) {
  try {
    const { data } = await $http.post(`${service}/organization`, payload);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function updateOrganization(organizationId, payload) {
  try {
    await $http.put(`${service}/organization/${organizationId}`, payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getContactByPhone = async function ({ companyId, number }) {
  try {
    const response = await $http.get(`${service}/contact/phone/${number}?companyId=${companyId}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getContactsByName = async function ({ offset, limit, name }) {
  try {
    const { data } = await $http.get(`${service}/contact/name/${name}?limit=${limit}&offset=${offset}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getContactsByIdentifier = async (params) => {
  try {
    const { data } = await $http.get(`${service}/contact/identifier`, { params });
    return data;
  } catch (error) {
    throw Error(`Error getting contacts by identifier: ${error}`);
  }
};

export const getAllContactsQuery = async function ({ offset, limit }) {
  try {
    const { data } = await $http.get(`${service}/contact?limit=${limit}&offset=${offset}`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllContactsPaginated = async function (params) {
  try {
    const { data } = await $http.get(`${service}/contact/pagination`, { params });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllContacts = async function () {
  try {
    const { data } = await $http.get(`${service}/contact`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCategoryList = async function () {
  const { data } = await $http.get(`${service}/contact/category`);
  return data;
};

export const createCategory = async function (categoryData) {
  const { data } = await $http.post(`${service}/category`, categoryData);
  return data;
};

export const deletePhoneById = async (phoneId) => {
  try {
    const { data } = await $http.delete(`${service}/contact/phone/${phoneId}`);
    return { data };
  } catch (error) {
    throw Error(`Error deleting phone: ${error}`);
  }
};

export const uploadContactAvatar = async (contactId, file) => {
  try {
    const { data } = await $http.post(`${service}/contact/avatar/${contactId}`, file);
    return data;
  } catch (error) {
    throw Error(`Error uploading contact avatar: ${error}`);
  }
};

export const getCountries = async function () {
  const { data } = await $http.get(`${service}/contact/phone/countries`);
  return data;
};

export default {
  editContact,
  createContact,
  createCategory,
  getAllContacts,
  getContactById,
  destroyContact,
  deletePhoneById,
  getContactsByName,
  getContactByPhone,
  CHUNK_SIZE,
  bulkCreateContacts,
  createOrganization,
  getOrganizationById,
  updateOrganization,
  getAllContactsQuery,
  getOrganizationList,
  getOrganizationsCount,
  getAllContactsPaginated,
  getContactsByIdentifier,
  getCategoryList,
  uploadContactAvatar,
  getContactsByNameOrEmail,
  getContactsPhones,
  getCountries,
};
