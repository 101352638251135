<template lang="pug">
md-table.tableBox(
  style="margin-top: 24px",
  :value="users",
  md-sort="name",
  md-sort-order="asc",
  md-card
)
  md-field.md-toolbar-section-end(md-clearable)
  md-table-row.contact-clickable.cursor-pointer.rounded-md(
    slot="md-table-row",
    slot-scope="{ item }",
    :ref="item.id",
    @click="passContactId(item.id)"
    class="text-inside-brand" 
  )
    md-table-cell(name="nameTableCell", role="button", md-label="Nome")
      .list-user-img 
        .firstLetter(v-if="item.invalid") {{ item.name[0].toUpperCase() }}
        img(
          v-else-if="!item.invalid",
          style="border-radius: 4px; width: 32px; height: 32px",
          :src="`${requestLinks.clist}/uploads/${item.id}.jpg`",
          @error="item.invalid = true; $forceUpdate()"
        ) 
      .text-marker.font-semibold {{ item.name?.substring(0, 17) }}
      div(v-if="item.name?.length > 17") ...

    md-table-cell.text-marker(md-label="Telefone Principal") {{ item.phones?.length > 0 && item.phones[0].number ? formatInputValue(item.phones[0]).substring(0, 17) : '' }}
      div(v-if="item.phones?.length > 0 && item.phones[0].number && formatInputValue(item.phones[0]).length > 17") ...
      .unknown-text(v-if="!item.phones?.length || !item.phones[0].number") Sem telefone
    md-table-cell.text-marker(md-label="Organização") {{ item.organization?.name.substring(0, 17) }}
      div(v-if="item.organization && item.organization.name?.length > 17") ...
      .unknown-text(v-if="!item.organization?.name") Sem organização
    md-table-cell(md-label="Categoria")
      .client-text(
        v-if="item.category && item.category.name",
        style="margin-bottom: 0px; font-size: 14px"
      ) {{ item.category.name }}
      .unknown-text(v-else) Sem categoria

    md-table-cell.text-marker(md-label="Data de criação") {{ $moment(item.createdAt).format('DD/MM/yyyy - HH:mm') }}
    md-table-cell(style="position: relative")
      v-dropdown(placement="bottom")
        .material-icons.notranslate.text-gray-400.cursor-pointer.transition.mt-1(
          class="hover:text-gray-600"
          @click.stop
        ) more_vert
        template(#popper)
          ul.divide-y(v-close-popper)
            dropdown-item(
              @click="$router.push({ name: 'EditContact', params: { id: item.id } })",
              label="Editar usuário",
              icon="e3c9"
            )
            dropdown-item(
              @click="$emit('opencallback', item)",
              label="Atribuir retorno",
              icon="e85d"
            )
            dropdown-item(
              label="Iniciar conversa",
              icon="e0b7",
              @click="$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: item } })"
            )
</template>

<script>
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import MenuIcon from './Contact.Table.Icons.vue';
import { Dropdown } from 'floating-vue';
export default {
  props: ['users'],
  name: 'TableFixed',
  components: {
    MenuIcon,
    VDropdown: Dropdown,
    DropdownItem,
  },
  data: () => ({
    companyUserList: {},
    valid: true,
    loaded: false,
    itemName: '',
  }),
  computed: {
    userList() {
      return this.users.slice();
    },
  },
  methods: {
    formatInputValue(number) {
      try {
        if (number.number?.length > 4 && (number.number.startsWith('55') || number?.ddi == '55')) {
          return !number.ddi && !number.isWhatsapp
            ? number.number
            : number.country != 'BR'
            ? `+${number.ddi} ${number.number}`
            : this.phoneUtil.format(
                this.phoneUtil.parseAndKeepRawInput(number.number, number.country || 'BR'),
                this.PNF.INTERNATIONAL,
              );
        } else if (number.number?.length === 4) {
          return 'SIP ' + number.number;
        }
        return number.number;
      } catch (e) {
        return number.number;
      }
    },
    checkDisplay(event) {
      if (this.loaded) {
        if (event.target.className != 'contact-edit-box' && event.target.className != 'fa elipsis-icon fa-ellipsis-v') {
          for (var i = 0; i < this.userList?.length; i++) {
            this.$refs[this.userList[i].name].style.display = 'none';
          }
        }
      }
    },
    imageUrl(id) {
      return `${this.requestLinks.clist}/uploads/${id}.jpg`;
    },
    displayEditBox(nome) {
      var contactElement = this.$refs[nome];
      for (var i = 0; i < this.userList?.length; i++) {
        if (this.userList[i].name != nome) {
          this.$refs[this.userList[i].name].style.display = 'none';
        }
      }
      if (contactElement.style.display == 'flex') {
        contactElement.style.opacity = 0;
        setTimeout(() => {
          contactElement.style.display = 'none';
        }, 150);
      } else {
        contactElement.style.display = 'flex';
        setTimeout(() => {
          contactElement.style.opacity = 1;
        }, 1);
      }
    },
    passContactId(id) {
      this.$router.push({ name: 'ContactDetail', params: { id: id } });
    },
  },
  mounted() {
    this.loaded = true;
  },
};
</script>

<style lang="scss">
.md-table-row {
  pointer-events: none;
}
.contact-clickable {
  pointer-events: all;
}
.unknown-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #adb5bd;
}
.firstLetter {
  background-image: linear-gradient(156.96deg, var(--brand-color) 9.13%, var(--brand-orange) 103.34%) !important;
  font-size: 20px;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.list-user-img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-color-op-1) !important;
}
.md-card {
  z-index: 0 !important;
}
</style>
