<template lang="pug">
.mt-0.content
  .container-fluid.pb-6
    .pt-8.m-0.pagearea
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          .flex.gap-2.justify-start.items-center.p-2.w-fit.transition-all(
            class="hover:bg-zinc-200 rounded-full"
            @click="$router.push({ name: 'Contatos' })",
            role="button",
          )
            i.material-icons.notranslate.text-brand-500.text-xl &#xe5c4
            span.font-inter.text-zinc-700.font-medium Voltar
          a.title(style="margin: 0px") Organizações
      .header.flex.gap-8
        .w-full
          icon-input.w-full(
            label="Nome da organização",
            v-model="searchValue"
          )
        .flex.gap-4
          v-button.h-fit.min-w-fit(@click="addNewOrganization") Criar organização
      loading-ring.mt-10(v-if="loading")
      .w-full.flex.flex-col.gap-4.pb-6
        md-table.tableBox.w-full.mt-12(
          v-if="organizationsCount > 0 && !loading"
          :value="organizations", 
        )
          md-field.md-toolbar-section-end(md-clearable)
          md-table-row.contact-clickable.cursor-pointer.rounded-md(
            slot="md-table-row",
            slot-scope="{ item }",
            class="text-inside-brand" 
            @click="$router.push({ name: 'EditOrganization', params: { id: item.id } })"
          )
            md-table-cell(role="button", md-label="Nome") {{ item.name }}
            md-table-cell(role="button", md-label="Telefone Principal")
              p(:class="{'text-gray-400' : !item.phone}") {{ item.phone ? item.phone : 'Sem registro' }}
            md-table-cell(role="button", md-label="E-mail")
              p(:class="{'text-gray-400' : !item.mail}") {{ item.mail ? item.mail : 'Sem registro' }}
            md-table-cell(role="button", md-label="CNPJ")
              p(:class="{'text-gray-400' : !item.cnpj}") {{ item.cnpj ? item.cnpj : 'Sem registro' }}
            md-table-cell(role="button", md-label="Site")
              p(:class="{'text-gray-400' : !item.site}") {{ item.site ? item.site : 'Sem registro' }}
        pagination.ml-auto.mr-6(
          :hasMorePages="hasMorePages"
          :perPage="limitItems"
          @limit="setLimit"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          :totalItems="organizationsCount"
        )
        p.mt-10(v-if="!loading && organizationsCount === 0") Nenhuma organização encontrada
</template>
<script>
import IconInput from '@/components/Inputs/IconInput.vue';
import VButton from '@/components/Buttons/v-button.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import cListService from '@/services/contact-list.js';
import Pagination from '@/components/Pagination.vue';
import vTable from '@/components/Tables/v-table.vue';
import { debounce } from '@/helpers';

export default {
  title: 'Opens - Organizações',
  components: {
    LoadingRing,
    Pagination,
    IconInput,
    VButton,
    vTable,
  },
  data() {
    return {
      searchValue: '',
      organizationsCount: 0,
      currentPage: 1,
      limitItems: 10,
      organizations: [],
      loading: false,
    };
  },
  computed: {
    hasMorePages() {
      const totalPages = Math.ceil(this.organizationsCount / this.limitItems);
      return this.currentPage < totalPages;
    },
    currentModal() {
      return this.$store.getters['modal/activeModal'];
    },
  },
  watch: {
    searchValue(val) {
      this.searchOrganization(val);
    },
    async currentModal(val) {
      if (!val) await this.getOrganizationsItems();
    },
  },
  methods: {
    async getOrganizationsItems() {
      if (this.loading) return;
      this.loading = true;
      try {
        const payload = {
          limit: this.limitItems,
          offset: this.currentPage * this.limitItems - this.limitItems,
          companyId: this.user.company_id,
        };
        if (this.searchValue) payload.searchName = this.searchValue;
        const data = await cListService.getOrganizationList(payload);
        this.organizations = data;

        const total = await cListService.getOrganizationsCount({
          searchName: this.searchValue,
          companyId: this.user.company_id,
        });
        this.organizationsCount = total.total;
      } catch (e) {
        if (e.response.status === 401) window.location.pathname = '/auth/login';
        this.$toast.error('Falha ao buscar organizações');
      } finally {
        this.loading = false;
      }
    },
    async onPageChange(page) {
      this.currentPage = page;
      await this.getOrganizationsItems();
    },
    setLimit(value) {
      this.limitItems = value;
      this.getOrganizationsItems();
    },
    searchOrganization: debounce(async function (value) {
      this.currentPage = 1;
      this.searchValue = value;
      await this.getOrganizationsItems();
    }, 500),
    addNewOrganization() {
      this.$store.commit('modal/setActiveModal', 'CreateOrganization');
    },
  },
  async created() {
    await this.getOrganizationsItems();
  },
};
</script>
