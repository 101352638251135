import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_RECORDS}`;

export const getAttachmentById = async (fileId, provider) => {
  try {
    const { data } = await $http.get(`${service}/uploads/${fileId}`, {
      headers: {
        'x-bucket-provider': provider,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 *
 * @param {FormData} formData
 * @param {string} formData.append('file', file)
 * @param {string} provider
 * @returns
 */
export const uploadFile = async (formData, provider) => {
  try {
    const { data } = await $http.post(`${service}/uploads`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-bucket-provider': provider,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

/**
 *
 * @param {FormData} formData
 * @param {string} formData.append('file', file)
 * @param {string} path
 * @returns
 */
export const uploadFileToS3 = async (formData, path) => {
  try {
    const { data } = await $http.post(`${service}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-bucket-provider': path,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const convertAudioToM4A = async (formData) => {
  const { data } = await $http.post(`${service}/whatsapp/audio-convert`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  return data;
};

export default {
  getAttachmentById,
  uploadFile,
  convertAudioToM4A,
};
