<template lang="pug">
nav.navbar.navbar-expand-lg(v-if="user")

  .flex.w-full.gap-2.items-center.justify-end

    // Dialer
    dialer-tour(
      v-if="showDialerTour && showNewDialer"
      @tour-completed="showDialerTour = false"
    )
    dialer(v-if="!showDialerTour && showNewDialer")

    // Pausa
    pause-alert(
      v-if="user.profile === 'p_agent' && user.pause"
      :class="{'ml-0': user.profile === 'p_manager' || user.profile === 'p_admin'}"
    )
    pause-button(
      v-else
      :class="{'ml-0': user.profile === 'p_manager' || user.profile === 'p_admin'}"
    )

    // Ajuda
    a.material-icons-outlined.notranslate.text-gray-600.cursor-pointer.transition-all(
      class="hover:opacity-60"
      href="https://suporte.opens.com.br/",
      target="_blank",
      v-tooltip="'Abrir Portal do Suporte'"
    ) help

    // Notificações
    notification-icon(
      v-tooltip="'Notificações'"
    )

    // Perfil
    v-dropdown.mr(distance="12")
      .user-box.cursor-pointer.border.rounded-full
        .relative
          .absolute.w-3.h-3.z-10.-bottom-1.-right-0.rounded-full(
            v-if="!showNewDialer",
            :class="statusBallColor",
            class="dark:invert"
          )
          img.user-img.w-10.h-10.object-cover.aspect-square.rounded-full(
            v-if="!invalidAvatar && user.avatar != null",
            :src="`https://${csAddress}/avatar/${user.id}.png?version=${userAvatarCache}`",
            ref="img",
            :key="userAvatarCache"
            @error="invalidAvatar = true"
          )
          .user-img.firstLetter.rounded-full(v-else-if="user.username") {{ user.username[0].toUpperCase() }}
      template(#popper)
        .flex.flex-col.items-start.p-4.gap-3
          .flex.flex-col.gap-1.items-start.w-full
            p.font-plus.text-gray-600.font-semibold {{ user.username }}
            p.font-plus.text-gray-400 {{ user.email }}
          .flex.items-center.gap-1
            .material-icons.notranslate.text-gray-700 person
            p.font-plus.text-gray-700 {{ translateProfile[user.profile] }}
          .w-full.bg-gray-200(class="h-[1px]")
          .flex.flex-col.gap-1.items-start.w-full
            .flex.gap-4.pl-2.mb-1
              switch-checkbox.mb-0(v-model="darkMode")
              p.text-zinc-400.user-dropdown__option(class="dark:text-zinc-800 dark:font-semibold") Tema escuro
            a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(
              target="_blank"
              href="../v1/panel"
              v-if="loadedManagerAccessPermissionInformation && hasPermissionToAccessManager"
            )
              span.mr-4.material-icons.notranslate.not-action &#xe915
              a.text-zinc-400.font-plus.user-dropdown__option(
                class="dark:text-zinc-800 dark:font-semibold"
              ) Acessar ambiente de gestão
            a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(:href="`#/profile`")
              span.mr-4.material-icons.notranslate.not-action &#xe7fd
              a.text-zinc-400.font-plus.user-dropdown__option(
                class="dark:text-zinc-800 dark:font-semibold"
              ) Perfil e segurança
            a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(@click="logout")
              span.mr-4.material-icons.notranslate.not-action &#xe9ba
              a.text-zinc-400.font-plus.user-dropdown__option(
                class="dark:text-zinc-800 dark:font-semibold"
              ) Sair
</template>

<script>
import NotificationIcon from '@/components/Icons/NotificationIcon.vue';
import SwitchCheckbox from '@/components/Inputs/SwitchCheckbox.vue';
import PauseButton from '@/components/Buttons/pause-button.vue';
import DialerTour from '@/components/Dialer/dialer-tour.vue';
import PauseAlert from '@/components/Modals/PauseAlert.vue';
import vButton from '@/components/Buttons/v-button.vue';
import Dialer from '@/components/Dialer/index.vue';
import csService from 'src/services/cservice.js';
import { Dropdown } from 'floating-vue';

export default {
  components: {
    VDropdown: Dropdown,
    NotificationIcon,
    SwitchCheckbox,
    PauseButton,
    PauseAlert,
    DialerTour,
    vButton,
    Dialer,
  },
  data() {
    return {
      showDialerTour: true,
      csAddress: process.env.VUE_APP_CSERVICE,
      userAvatarCache: 0,
      loadedManagerAccessPermissionInformation: false,
      hasPermissionToAccessManager: false,
      invalidAvatar: false,
      darkMode: false,
      translateProfile: {
        p_agent: 'Agente de atendimento',
        p_manager: 'Gestor',
        p_admin: 'Admin',
        p_corporative: 'Corporativo',
      },
      newDialerListedCompanies: process.env.VUE_APP_NEW_DIALER_WHITE_LIST,
    };
  },
  computed: {
    showNewDialer() {
      if (!this.newDialerListedCompanies) return false;
      if (!this.newDialerListedCompanies?.split(',')?.includes(this.user.company_id)) return false;

      const showNewDialer = localStorage.getItem('@yunique:use-new-dialer');

      if (showNewDialer === null) localStorage.setItem('@yunique:use-new-dialer', 'true');
      if (showNewDialer === 'true') return true;

      return false;
    },
    nextarUserIds() {
      return process.env.VUE_APP_NEXTAR_USER_IDS;
    },
    isDarkTheme() {
      return window.localStorage.getItem('yunique_dark_mode');
    },
    isPaused() {
      return this.$store.getters['IS_PAUSED'] || this.$store.getters['GET_PAUSE'].ispaused;
    },
    statusBallColor() {
      if (this.user.oncall) return 'bg-rose-500';
      if (this.user.pause || this.isPaused) return 'bg-orange-400';
      return 'bg-emerald-400';
    },
  },
  watch: {
    user(value) {
      this.userAvatarCache++;
      this.$store.commit('SET_IS_PAUSED', !!value.pause);
    },
    darkMode(value) {
      if (!value) {
        document.querySelector('html').classList.remove('dark');
      } else {
        document.querySelector('html').classList.add('dark');
      }
      this.$store.commit('user/SET_DARKMODE', value);
      window.localStorage.setItem('yunique_dark_mode', value);
    },
    isDarkTheme(value) {
      this.darkMode = value;
    },
  },
  methods: {
    async checkHasPermissionToAccessManager() {
      if (this.nextarUserIds.includes(this.user.id)) return (this.hasPermissionToAccessManager = true);
      if (this.user.profile == 'p_admin' || this.user.profile == 'p_manager')
        return (this.hasPermissionToAccessManager = true);
      const { getUserBond } = csService;
      try {
        const userInformationWithAllBonds = await getUserBond(this.user.id);
        if (
          userInformationWithAllBonds.users_bonds.filter((userBond) => {
            return (
              userBond.type == 'queue' && (userBond.permission == 'superviser' || userBond.permission == 'manager')
            );
          }).length >= 1
        )
          return (this.hasPermissionToAccessManager = true);
      } catch (e) {
        return (this.hasPermissionToAccessManager = false);
      }
    },
    async logout() {
      try {
        if (this.user.profile == 'p_agent') {
          await csService.logout();
        }
      } catch (e) {
      } finally {
        this.$cookies.remove('snep7');
        window.location.href = '/auth/logout';
      }
    },
  },
  async created() {
    if (localStorage.getItem('dialerTourCompleted')) this.showDialerTour = false;

    await this.checkHasPermissionToAccessManager();
    this.loadedManagerAccessPermissionInformation = true;

    const dark = window.localStorage.getItem('yunique_dark_mode');
    this.darkMode = dark == 'true' ? true : false;
    if (!this.darkMode) {
      document.querySelector('html').classList.remove('dark');
    } else {
      document.querySelector('html').classList.add('dark');
    }
  },
};
</script>
<style lang="scss" scoped>
.bug-report {
  font-size: 24px;
  margin-right: 30px;
  display: grid;
  place-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-color);
}

.menu {
  min-width: fit-content;
  margin-left: auto;
}

.header-navigator {
  display: flex;
  cursor: pointer;

  .user-dropdown {
    padding: 24px 0px 24px 0px;
    width: 347px;
    background: #ffffff;
    position: absolute;
    margin-top: 28px;
    right: 130px;
    z-index: 1;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &__option {
      background: #ffffff;
      width: 100%;
      height: 48px;
      padding-left: 31px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      line-height: 17px;
      color: #868e96;
      transition: 0.2s;

      &:hover {
        background: #f8f9fa;
      }
    }

    &__header {
      padding: 8px 32px 24px 32px;

      .user-box {
        border-bottom: 1px solid #e9ecef;
        padding-bottom: 24px;
      }
    }
  }

  .header-navigator {
    position: relative;
  }

  .user-box {
    display: flex;
    gap: 12px;

    .username {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #495057;
    }

    .profile {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #adb5bd;
    }

    .user-img {
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      position: relative;
      top: none;
      background: var(--brand-color) 26;
      color: #fff;
    }

    .caret {
      color: var(--brand-color);
      font-size: 22px !important;
    }
  }
}

.navbar {
  z-index: 20;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-nav {
    display: contents;
  }

  .header-navigator {
    .user-dropdown {
      right: 0;
    }
  }

  .menu {
    width: 100%;
  }
}
</style>
