<template lang="pug">
.flex.flex-col.items-start.w-full.gap-2
  .flex.gap-2.flex-wrap
    v-tooltip(
      v-for="(file, index) in files"
      :key="`${file.name}-${index}`"
      :show-triggers="[]"
      :shown="showLoadedFileTooltip && index === files.length - 1"
      :auto-hide="true"
    )
      template(#popper)
        .flex.gap-1
          i.material-icons-round.notranslate.text-green-500.bg-white.rounded-full check_circle
          p.font-plus Arquivo carregado!
      .flex.gap-1.min-w-max.border.border-2.border-brand-50.bg-opacity-70.bg-white.rounded-full.h-12.shadow-sm.px-2.transition-all.relative(
        class="hover:border-brand-100 hover:shadow-md"
      )
        .flex.gap-2
          i.material-icons.notranslate.text-brand-200.select-none {{ mediaTypes.find(mediaType => mediaType.type === file.fileType)?.icon }}
          p.font-plus.font-semibold {{ mediaPreviewLabel(file.file) }}
          i.select-none.cursor-pointer.material-icons.notranslate.text-red-500.text-sm.font-semibold.bg-transparent.rounded-full.h-6.w-6.flex.items-center.justify-center.transition-colors(
            class="hover:bg-brand-100"
            @click="removeFile(index)"
            v-tooltip="'Remover'"
          ) close

  .flex.flex-col.justify-start.items-start.line-break.break-anywhere.w-full.gap-2.relative
    ckeditor(
      :editor="editor" 
      v-model="text" 
      :config="editorConfig"
      @ready="setEditor"
    )
    .flex.absolute.left-36.ml-1.select-none.mt-1.gap-1 
      f-dropdown.select-none
        .flex
          .flex.rounded-sm.transition-all.p-1.rounded-sm.border.border-transparent(
            class="hover:bg-zinc-100 active:border-blue-500"
            title="Emoji"
          )
            .material-icons.text-black(class="text-[22px]") mood
        template(#popper)
          emoji-input(
            @emojiSelected="addEmojiToInput"
          )
      f-dropdown.select-none
        .flex
          .flex.rounded-sm.transition-all.p-1.rounded-sm.border.border-transparent.cursor-pointer(
            class="hover:bg-zinc-100 active:border-blue-500"
            v-tooltip="hasExceededFilesSizeLimit  ? 'Anexos ultrapassaram o limite de 25mb' : 'Enviar arquivos'"
            :class="{'!cursor-not-allowed' : hasExceededFilesSizeLimit}"
          )
            .material-icons(:class="['text-[22px]', hasExceededFilesSizeLimit ? '!text-red-500' : 'text-black']") attach_file
        template(#popper, v-if="!hasExceededFilesSizeLimit")
          attach-file-input(
            :mediaTypes="mediaTypes"
            @file-selected="handleInputFileSelect"
          )
    .flex.absolute.right-0.p-1(v-if="hasSendButton")
      .flex.items-center.justify-center
        .flex.p-1.rounded-sm.transition-all(
          :class="{'cursor-pointer bg-brand-500 hover:opacity-60 active:scale-90' : isAbleToSend, 'cursor-not-allowed bg-brand-200' : !isAbleToSend }"
          v-tooltip="{html: true, content: sendButtonTooltipLabel}"
          @click="handleSendEmail"
        )
          .material-icons.text-white send
</template>
<script>
import { Dropdown, Tooltip } from 'floating-vue';
import EmojiInput from '@/components/Inputs/EmojiInput.vue';
import AttachFileInput from '@/components/Inputs/AttachFileInput.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js';
import 'floating-vue/dist/style.css';
import { uploadFileToS3 } from '@/services/records';
export default {
  props: ['initial-text', 'has-send-button', 'draft-text'],
  components: {
    ckeditor: CKEditor.component,
    FDropdown: Dropdown,
    ClassicEditor,
    EmojiInput,
    AttachFileInput,
    VTooltip: Tooltip,
  },
  data() {
    return {
      text: '',
      editor: ClassicEditor,
      textEditor: {},
      editorConfig: {
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
        language: 'pt-br',
      },
      showLoadedFileTooltip: false,
      mediaTypes: [
        {
          type: 'image',
          icon: 'image',
          label: 'Imagem',
          accept: 'image/*',
          limit: 25, // 25 MB
        },
        {
          type: 'video',
          icon: 'videocam',
          label: 'Video',
          accept: 'video/*',
          limit: 25, // 25 MB
        },
        {
          type: 'document',
          icon: 'description',
          label: 'Documento',
          accept:
            'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
          limit: 25, // 25 MB
        },
        {
          type: 'audio',
          icon: 'library_music',
          label: 'Audio',
          accept: 'audio/*, .amr',
          limit: 25, // 25 MB
        },
      ],
      files: [],
      uploadingFile: false,
    };
  },
  computed: {
    sendButtonTooltipLabel() {
      if (!this.hasExceededFilesSizeLimit) return 'Enviar email';
      return 'Não é possível enviar ese email!<br>Anexos ultrapassaram o limite de 25mb<br><br><p class="text-xs">remova alguns anexos e tente novamente</p>';
    },
    isAbleToSend() {
      return (this.text?.length > 0 || this.files.length > 0) && !this.uploadingFile && !this.hasExceededFilesSizeLimit;
    },
    activeEmail() {
      this.userAvatarCache++;
      return this.$store.getters['email/activeEmail'];
    },
    attachments() {
      return this.files.map((file) => ({
        id: file.id,
        link: file.link,
        filename: file.file.name,
        contentType: file.file.type,
      }));
    },
    filesTotalSize() {
      return (
        this?.files?.reduce((acc, file) => {
          return acc + file?.file?.size || 0;
        }, 0) || 0
      );
    },
    hasExceededFilesSizeLimit() {
      const UPLOAD_SIZE_LIMIT = 25 * 1024 * 1024; // 25MB em bytes
      return this.filesTotalSize > UPLOAD_SIZE_LIMIT;
    },
  },
  methods: {
    handleSendEmail() {
      if (!this.isAbleToSend) return;
      this.$emit('send-email', {
        attachments: this.attachments,
      });
    },
    addEmojiToInput(emoji) {
      const content = emoji;
      const viewFragment = this.textEditor.data.processor.toView(content);
      const modelFragment = this.textEditor.data.toModel(viewFragment);
      this.textEditor.model.insertContent(modelFragment);
    },
    setEditor(e) {
      this.textEditor = e;
    },
    mediaPreviewLabel(file) {
      if (file?.name?.length < 20) return file?.name;
      return file.name.substring(0, 20) + '...' + file.name.split('.').pop();
    },
    removeFile(fileIndex) {
      this.showLoadedFileTooltip = false;
      this.files.splice(fileIndex, 1);
    },
    async handleInputFileSelect({ file, fileType }) {
      this.uploadingFile = true;

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await uploadFileToS3(formData, 'opens');

        this.files.push({
          file: {
            size: file.size,
            name: file.name,
            type: file.type,
          },
          fileType,
          id: response?.id,
          link: response?.link,
          uploadedAt: new Date().toISOString(),
        });

        this.showLoadedFileTooltip = true;
        setTimeout(() => {
          this.showLoadedFileTooltip = false;
        }, 1500);
      } catch (e) {
        console.error(e);
        this.$toast.error('Erro ao enviar arquivo');
      } finally {
        this.uploadingFile = false;
      }
    },
    loadAttachmentsFromDraft() {
      const files = localStorage.getItem(`@attachment-draft-files-${this.activeEmail?.id}`);
      if (!files) return (this.files = []);

      this.files = JSON.parse(files).filter((file) => this.$moment().diff(file.uploadedAt, 'hours') < 24);
    },
  },
  watch: {
    text(val) {
      this.$emit('write', val);
      this.$emit('draft', val);

      if (!val?.length) this.loadAttachmentsFromDraft();
    },
    initialText(val) {
      this.text = val;
    },
    draftText(val) {
      this.text = val;
    },
    files(val) {
      localStorage.setItem(`@attachment-draft-files-${this.activeEmail?.id}`, JSON.stringify(val));
    },
    activeEmail() {
      this.loadAttachmentsFromDraft();
    },
  },
  created() {
    this.text = this.initialText;
    this.loadAttachmentsFromDraft();
  },
};
</script>
