<template lang="pug">
.flex.flex-col.p-2.gap-1
  .text-brand.font-semibold.font-plus Selecione um tipo de arquivo
  .flex.gap-3
    v-tooltip.p-2.flex.flex-col.custom-file-upload.cursor-pointer.rounded-md.relative.justify-end.group.w-20.h-24(
      v-for="mediaType in mediaTypes"
      class="hover:bg-brand-500 hover:bg-opacity-5 dark:invert"
      :key="mediaType.type"
    )
      div.flex.items-center.flex-col
        .flex.h-12.w-12.bg-brand-gradient.transition-all.rounded-full
          i.select-none.material-icons.notranslate.text-white.text-2xl {{ mediaType.icon }}
          input.opacity-0.absolute.top-0.left-0.w-full.h-full.cursor-pointer(
            type="file",
            :accept="mediaType.accept"
            @input="handleInputFileSelect(mediaType.type)",
            :name="mediaType.type",
            title=""
            ref="fileInput"
            :key="inputKey"
          )
        .text-gray-500.cursor-pointer.font-plus(
        ) {{ mediaType.label }}
      template(#popper)
        span {{ `Selecione um arquivo de até ${mediaType.limit}MB` }}        
</template>

<script>
import LoadingRing from '@/components/LoadingRing.vue';
import { Tooltip, hideAllPoppers } from 'floating-vue';

export default {
  props: {
    mediaTypes: {
      type: Array,
      required: true,
    },
  },
  components: {
    LoadingRing,
    VTooltip: Tooltip,
  },
  data() {
    return {
      inputKey: 0,
    };
  },
  methods: {
    async handleInputFileSelect(fileType) {
      try {
        const mediaTypeConfig = this.mediaTypes.find((type) => type.type === fileType);
        const selectedLimit = mediaTypeConfig.limit;
        const file = event.target.files[0];
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > selectedLimit) {
          return this.$toast.error(`O tamanho do arquivo não pode ser maior que ${selectedLimit}MB`);
        }

        this.$emit('file-selected', { file, fileType });
      } catch (error) {
        console.error(error);
      } finally {
        this.inputKey++;
        hideAllPoppers();
      }
    },
  },
  async created() {},
};
</script>
