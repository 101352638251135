<template>
  <div>
    <portal-target name="modal-outlet"></portal-target>
    <portal-target name="modal-outlet-2"></portal-target>
    <notification-off-alert
      v-if="showNotificationsOffAlert"
      @close="showNotificationsOffAlert = false"
    ></notification-off-alert>
    <div v-if="loaded" :class="{ 'nav-open': $sidebar.showSidebar }">
      <div class="fixed z-40 w-full pointer-events-none top-24">
        <!-- <dialer-alert v-if="collapseDialer && activeDialer"></dialer-alert> -->
        <chat-alert
          v-if="
            (pendingMessages.length > 0 ||
              invites.filter((invite) => chatProviders.includes(invite.provider)).length > 0) &&
            this.$route.name !== 'Chat'
          "
        ></chat-alert>
        <email-alert
          v-if="
            (pendingMessages.length > 0 ||
              invites.filter((invite) => emailProviders.includes(invite.provider)).length > 0) &&
            this.$route.name !== 'Emails'
          "
        ></email-alert>
      </div>
      <confirm-pause-modal v-if="this.$store.getters['GET_MODAL'] === 'confirmPause'"></confirm-pause-modal>
      <link-case v-if="this.$store.getters['GET_MODAL'] === 'linkCase'"></link-case>
      <notifications></notifications>
      <component v-if="modal" :is="modal"></component>
      <router-view></router-view>
    </div>
    <div class="loading-page" v-else="">
      <div class="loading-page-header">
        <div class="loading-img dark:invert"></div>
      </div>
      <div class="loading-page-body">
        <div class="loading-bar" v-if="!error">
          <div class="loading-bar-inside"></div>
        </div>
        <div class="loading-text" v-if="!error">Carregando...</div>
        <div class="loading-texts" style="width: 224px" v-else="">
          <div class="confirm-circle" style="margin-bottom: 24px">
            <div class="confirm-inside-circle" style="color: white; font-size: 18px">!</div>
          </div>
          <div class="loading-error-text" style="font-size: 20px; color: #495057; width: max-content">
            Oops! Parece que ocorreu um problema
          </div>
          <div class="dialer-error-subtext" style="font-size: 16px; width: 339px">
            Para solucionar, verifique sua conexão e tente novamente.
          </div>
          <a class="history-button" href="." style="margin-top: 33px">Tentar novamente</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalComponents from '@/components/Modals';
import StartChat from '@/components/Modals/StartChat.vue';
import StartEmail from '@/components/Modals/StartEmail.vue';
import StartChatTemplate from '@/components/Modals/StartChatTemplate.vue';
import MessageHistory from '@/components/Modals/MessageHistory.vue';
import CreateOrganization from '@/components/Modals/CreateOrganization.vue';
import LinkCase from '@/components/Modals/LinkCase.vue';
import connectionLost from '@/components/Alerts/connection-lost.vue';
import connectionEstablished from '@/components/Alerts/connection-established.vue';
import DialerAlert from '@/components/Alerts/minimized.vue';
import ChatAlert from '@/components/Alerts/chat.vue';
import EmailAlert from '@/components/Alerts/email.vue';
import eventBus from '@/main-event-bus.js';
import NotificationOffAlert from '@/components/Alerts/notification-off-alert.vue';
import licensesService from '@/services/licenses.js';
import customerService from './services/cservice';
import useGetDeviceRegistryMixin from '@/mixins/cstate/use-get-device-registry';
import chatFetchRoomsMixin from '@/mixins/chat/fetch-rooms.mixin';
import chatService from '@/services/chat.js';
import SipMixin from '@/mixins/invite-calls.js';

export default {
  components: {
    ...ModalComponents,
    LinkCase,
    StartChat,
    StartChatTemplate,
    MessageHistory,
    connectionLost,
    connectionEstablished,
    DialerAlert,
    ChatAlert,
    NotificationOffAlert,
    CreateOrganization,
    StartEmail,
    EmailAlert,
  },
  mixins: [useGetDeviceRegistryMixin, chatFetchRoomsMixin, SipMixin],
  data() {
    return {
      company: {},
      error: false,
      loaded: false,
      connectedAlert: false,
      showNotificationsOffAlert: false,
      cstateLatencyCheckClock: null,
    };
  },
  computed: {
    clarityProjectId() {
      return process.env.VUE_APP_CLARITY_ID;
    },
    emailProviders() {
      return process.env.VUE_APP_EMAIL_PROVIDERS;
    },
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
    chatSocketIsConnected() {
      return this.$store.getters['chat/socketConnected'];
    },
    loseChatSocketConnection() {
      return this.$store.getters['chat/loseSocketConnection'];
    },
    modal() {
      return this.$store.getters['modal/activeModal'];
    },
    isPaused() {
      return this.$store.getters['IS_PAUSED'] || this.$store.getters['GET_PAUSE'].ispaused;
    },
    isConnected() {
      return this.$store.getters['cstate/isConnected'];
    },
    pendingMessages() {
      return this.$store.getters['cstate/PENDING_MESSAGES'];
    },
    collapseDialer() {
      return this.$store.getters['collapseDialer'];
    },
    invites() {
      return this.$store.getters['chat/invites'];
    },
    activeDialer() {
      const dialerState = this.$store.getters['cstate/GET_DIALER_STATE'];
      if (dialerState == 1 || dialerState == 2 || dialerState == 4) return true;
      return false;
    },
    currentTitle() {
      return this.$store.getters['GET_CURRENT_PAGE_TITLE'];
    },
    roomsWithUnreadedMessages() {
      return this.$store.getters['chat/getRoomsWithUnreadedMessages'];
    },
  },
  methods: {
    updatePageTitle() {
      if (!Object.keys(this.roomsWithUnreadedMessages).length) return (document.title = this.currentTitle);
      document.title = `(${Object.keys(this.roomsWithUnreadedMessages).length}) ${this.currentTitle}`;
    },
    trackUserTransport() {
      try {
        this.$mixpanel.people.set({
          deviceTransport: this.deviceTransportConfig?.transport || 'tls',
        });
      } catch (error) {}
    },
    async initApp() {
      try {
        this.$store.commit('SET_TOKEN', this.$cookies.get('snep7'));
        this.$http.defaults.headers.common['Authorization'] = `Bearer ${this.accessToken}`;
        if (!this.accessToken) {
          window.location.href = '/auth/login';
        }
        await this.$store.dispatch('user/requestUser', this.$http);
        const company = await customerService.getCompanyById(this.user.company_id);
        this.company = company;
        await this.$store.dispatch('user/requestDevices', this.$http);
        await this.$store.dispatch('user/requestUserActions', this.$http);
        await this.$store.dispatch('performance/getUserWorkgroups', this.$http);
        this.getDeviceRegistry(this.user.endpoint);
        this.trackUser();
        this.$store.commit('SET_CONFIG', { timezone: this.user.timezone });
        this.$store.dispatch('cstate/socketConfig', {
          accessToken: this.accessToken,
          endpoint: this.user.endpoint,
          company_id: this.user.company_id,
          user_id: this.user.id,
          Vue: this,
        });
        this.$store.dispatch('chat/initialize', this.$store.getters['cstate/GET_SOCKET']);
      } catch (e) {
        return Promise.reject('Erro');
      }
    },
    initClarity() {
      if (!this.$clarity) return;
      this.$clarity.init(this.clarityProjectId);
      this.$clarity.identify(this.user.email);
    },
    async trackUser() {
      this.initClarity();
      this.$mixpanel.identify(this.user.id);
      this.$mixpanel.register({
        company_id: this.user.company_id,
        company_name: this.company.name,
        Company: this.user.company_id,
      });
      this.$mixpanel.people.set({
        $name: this.user.username,
        $email: this.user.email,
        companyId: this.user.company_id,
        username: this.user.username,
        email: this.user.email,
        profile: this.user.profile,
        occupation: this.user.occupation,
        clientVersion: `snep7ui ${process.env.VUE_APP_VERSION}`,
        active: true,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
      });

      try {
        this.startUserSession();
      } catch (e) {}
    },
    startUserSession() {
      const existingSession = localStorage.getItem('@opens:user_session');
      const existingSessionDate = this.$moment(existingSession);
      const isSameDay = this.$moment().isSame(existingSessionDate, 'day');

      if (isSameDay && existingSession) return;

      eventBus.$emit('UX-event', { eventName: 'daily-access', payload: { type_tracking: 'login' } });
      localStorage.setItem('@opens:user_session', this.$moment().toISOString());
    },
    notificationsPermission() {
      Notification.requestPermission().then((perm) => {
        if (perm === 'denied') this.showNotificationsOffAlert = true;
      });
    },
    checkCstateLatency() {
      const socket = this.$store.getters['cstate/GET_SOCKET'];
      if (!socket) return;
      socket.emit('latency-ping', {
        code: Math.floor(Math.random() * 100000),
        timestamp: this.$moment().toISOString(),
      });
      this.$store.commit('cstate/setPingDate', this.$moment().toISOString());
    },
    async setCurrentAllUnreadMessagesTotal() {
      const { countUnreadMessagesInAllUserRoom } = chatService;
      try {
        const data = await countUnreadMessagesInAllUserRoom({ userId: this.user.id });
        this.$store.commit('chat/setRoomsWithUnreadedMessages', data);
      } catch (error) {}
    },
  },
  async created() {
    const darkMode = window.localStorage.getItem('yunique_dark_mode') == 'true' ? true : false;
    if (!localStorage.getItem('@yunique:show-messages-notification'))
      localStorage.setItem('@yunique:show-messages-notification', 'true');
    if (!darkMode) {
      document.querySelector('html').classList.remove('dark');
    } else {
      document.querySelector('html').classList.add('dark');
    }
    /**
     * @param {Object} event
     * @param {string} event.eventName
     * @param {Object} event.payload
     */
    const emitMixpanelEvent = (event) => {
      if (!this.user?.id) return;
      try {
        if (typeof event !== 'object' || event === null) return;
        this.$mixpanel.track(event.eventName, { type_tracking: 'action', ...event.payload });
      } catch (error) {
        console.error('@mixpanel:', error);
      }
    };
    eventBus.$on('UX-event', emitMixpanelEvent);
    eventBus.$on('mixpanel-event', emitMixpanelEvent);
    eventBus.$on('open-context-area', (linkedid) => {
      this.$router.push({
        name: 'ContextArea',
        params: { callId: linkedid },
      });
    });
    eventBus.$on('invite-sent', () => {
      this.$toast.open({
        message: 'Convite para chamada enviado!',
        type: 'success',
        duration: 2000,
      });
    });
    eventBus.$on('call-failed', (message) => {
      this.$toast.error(message);
    });
    eventBus.$on('invite-failed', (statusCode) => {
      let code = parseInt(statusCode);
      let message = `[ Chamada encerrada com código: ${statusCode} ] `;
      if ([404, 604].includes(code)) message += 'Numero invalido ou não encontrado!';
      else if ([486, 600].includes(code)) message += 'Numero ocupado, tente novamente mais tarde!';
      else if ([408, 410, 430, 480].includes(code)) message += 'Serviço indisponível!';
      else if ([300, 301, 302, 305, 380].includes(code)) message += 'Chamada redirecionada...';
      else if ([484].includes(code)) message += 'Endereço incompleto';
      else if ([488, 606].includes(code)) message += 'SDP incompativel';
      else if ([401, 407].includes(code)) message += 'Erro de autenticação';
      else if ([403, 603].includes(code)) message += 'Chamada rejeitada';
      this.$toast.warning(message);
    });
    eventBus.$on('useragent-failed', () => {
      this.$toast.open({
        message: 'Erro ao conectar webphone',
        type: 'error',
      });
    });

    try {
      await this.initApp();
      this.loaded = true;
      const data = await licensesService.getCompanyPlans({
        companyId: this.user.company_id,
      });
      this.$store.commit('license/setPlan', data.plan);
    } catch (e) {
      console.error(e);
      this.error = true;
    }
    await this.setCurrentAllUnreadMessagesTotal();
    setTimeout(() => {
      if (!Object.keys(this.roomsWithUnreadedMessages).length) return;
      this.updatePageTitle();
    }, 1000 * 60 * 5);
  },
  beforeDestroy() {
    clearInterval(this.cstateLatencyCheckClock);
  },
  mounted() {
    if (Notification.permission != 'granted') this.showNotificationsOffAlert = true;
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://opensdev.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=pt-BR&collectorId=e20208d1',
    );

    document.head.appendChild(recaptchaScript);
    window.parent.postMessage('hello from yunique!', '*');

    window.onbeforeunload = async (e) => {
      try {
        await this._hangUpCall();
        await this.$mixpanel.track('Unload Page');
      } catch (error) {
        console.error(error);
      }
      return null;
    };
    this.cstateLatencyCheckClock = setInterval(() => {
      this.checkCstateLatency();
    }, 1000 * 60 * 3);
  },
  watch: {
    roomsWithUnreadedMessages() {
      this.updatePageTitle();
    },
    currentTitle() {
      this.updatePageTitle();
    },
    isConnected(newVal, oldVal) {
      this.trackUserTransport();
      if (newVal) {
        this.$store.dispatch('sip/createUserAgent', {
          username: this.user.username,
          endpoint: this.user.endpoint,
        });
        return;
      }
      this.$store.commit('sip/destroyUserAgent');
    },
    $route(to, from, next) {
      this.$clarity?.identify(this.user.email);
      eventBus.$emit('UX-event', { eventName: 'Page Visit', payload: { page: to.name, type_tracking: 'screen' } });
      if (to.matched.some((record) => record.meta.conditionalRoute)) {
        if (this.user.type === 'opens') {
          document.title = 'Opens - Preferências';
          this.$router.push({ path: '/preferences/devices' });
        }
      }
      document.title = to.meta.title || 'Opens';
    },
    async chatSocketIsConnected(prev, next) {
      if (next || !this.loseChatSocketConnection) return;
      this.$store.dispatch('chat/reconnectSocket');
      await this.fetchUserChatRooms({ isOpen: true, orderMessages: 'desc' });
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import '@/assets/css/modulos/global';
@import '@/assets/css/modulos/dark-mode';
#atlwdg-trigger {
  display: none;
}
.loading-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 8px;
  color: #adb5bd;
}

.loading-error-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #343a40;
}

.loading-error-subtext {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #868e96;
}

.loading-page {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  background-image: url('./assets/images/loading-background.svg');
  background-size: 150% 50%;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.loading-page-header {
  width: 100%;
  height: 10%;
  padding-top: 48px;
  padding-left: 214px;
}

.loading-page-body {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-img {
  margin-bottom: 30px;
  background-image: url('./assets/images/opens-app-logo.png');
  width: 100px;
  height: 50px;
  background-size: 100% 100%;
}

.loading-bar {
  width: 150px;
  height: 5px;
  background: #e9ecef;
  border-radius: 100px;
}

.loading-bar-inside {
  width: 150px;
  position: absolute;
  height: 5px;
  border-radius: 100px;
  background: var(--brand-color);
  animation: loadBar 2s ease infinite;
}

@keyframes loadBar {
  0% {
    width: 0px;
  }

  100% {
    width: 150px;
  }
}

.md-body-1,
.md-body-2,
body {
  color: #343a40 !important;
  letter-spacing: normal !important;
}

.title {
  letter-spacing: normal !important;
}

.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}

//Calendar Styles
.calendar-box {
  height: 474px;
  background: #ffffff;
  border: 1px solid #e9ecef !important;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
}

.vc-container {
  --orange-900: #495057 !important;
  --orange-700: var(--brand-color) !important;
  --orange-600: var(--brand-color) !important;
  --orange-200: var(--brand-color-op-2) !important;
  --rounded-full: 0px !important;
}

.vc-title {
  font-family: 'Inter', sans-serif !important;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #495057 !important;
}

.vc-header {
  margin-top: 15px;
  margin-bottom: 10px;
}

.vc-arrows-container {
  margin-top: 15px;
}

.vc-day-content.vs-focusable {
  background: rgba(242, 108, 69, 0.2) !important;
  border-radius: 0px !important;
}

.vc-day-content {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  border-radius: 0px !important;
  text-align: center;
  color: #495057;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;

  &-col {
    flex-direction: column;
  }
}

.modal {
  &-bg {
    width: 100%;
    height: 100%;
  }
}
</style>
