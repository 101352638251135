<template lang="pug">
.event-box
  .upside
    span.material-icons.notranslate.transfer.rotate-90 phone_disabled
    span.event-text
      | Transferência retornada
    span.date-text(
      v-tooltip="formatTimestamp(data.createdAt)"
    ) {{ formatTimeDiff(data.createdAt, calldate) }}
  .downside
</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';
export default {
  mixins: [dateFormatMixin],
  props: ['data', 'calldate'],
};
</script>

<style lang="scss" scoped>
.event-box {
  width: 100%;
  height: 48px;
  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  .transfer {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: var(--red-default);
    color: #fff;
    font-size: 12.5px !important;
  }
  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    height: 22px;
  }
  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
